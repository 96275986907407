@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --foreground-rgb: 0, 0, 0;
  --background-start-rgb: 214, 219, 220;
  --background-end-rgb: 255, 255, 255;
}

@media (prefers-color-scheme: dark) {
  :root {
    --foreground-rgb: 255, 255, 255;
    --background-start-rgb: 0, 0, 0;
    --background-end-rgb: 0, 0, 0;
  }
}

body {
  color: rgb(var(--foreground-rgb));
  /*background: linear-gradient(
      to bottom,
      transparent,
      rgb(var(--background-end-rgb))
    )
    rgb(var(--background-start-rgb));*/
}

@layer utilities {
  .text-balance {
    text-wrap: balance;
  }
}

@import url("https://fonts.googleapis.com/css2?family=Public+Sans:ital,wght@0,100..900;1,100..900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

.font-sans {
  font-family: "Roboto", sans-serif;
}
.font-serif {
  font-family: "Public Sans", sans-serif;
}

body {
  @apply font-sans font-normal text-neutral-800 text-base md:text-lg;
}
body a {
  @apply no-underline text-blue-700;
}
.gray-shadow {
  box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0.2);
}
.text-shad {
  text-shadow: 0px 10px 1px 15px rgba(0, 0, 0, 0.8);
}
nav .navbar-menu a {
  @apply font-sans font-medium;
}
/* nav .navbar-menu .submenu a { @apply flex items-center font-medium text-gray-700 hover:text-white hover:bg-cyan-800 py-2 px-3; } */
/*body p, body div { @apply mb-4; }*/

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Public Sans", sans-serif;
  line-height: 1.2em !important;
}
h1 {
  @apply text-4xl md:text-5xl lg:text-6xl mb-4;
}
h1.heading {
  @apply text-3xl md:text-5xl lg:text-7xl mb-4;
}
h2 {
  @apply text-3xl md:text-4xl lg:text-5xl mb-4;
}
h3 {
  @apply text-2xl md:text-3xl lg:text-4xl mb-4;
}
h4 {
  @apply text-xl md:text-2xl lg:text-3xl mb-4;
}
h5 {
  @apply text-lg md:text-xl lg:text-2xl mb-4;
}
h6 {
  @apply text-base md:text-lg lg:text-xl mb-4;
}

.slider-sec {
  @apply bg-cover bg-no-repeat bg-center relative overflow-hidden;
}
.slider-sec:before {
  @apply bg-fixed absolute bottom-0 left-0 right-0 top-0 h-full w-full overflow-hidden;
}

.title-heading {
  @apply bg-cover bg-no-repeat bg-center relative overflow-hidden w-full;
}
.title-heading:before {
  @apply bg-fixed absolute bg-neutral-900 bg-opacity-10 bottom-0 left-0 right-0 top-0 w-full overflow-hidden transform;
}

.embla {
  overflow: hidden;
}
.embla__container {
  display: flex;
}
.embla__slide {
  flex: 0 0 100%;
  min-width: 0;
}

.silver-button {
  box-shadow: inset 0 0 0 1px #c9c9c9, inset 0 1px 2px rgba(255, 255, 255, 0.5),
    inset 0 -1px 2px rgba(0, 0, 0, 0.5);
  background: conic-gradient(
        #d7d7d7,
        #c3c3c3,
        #cccccc,
        #c6c6c6,
        #d3d3d3,
        #d8d8d8,
        #d5d5d5,
        #d8d8d8,
        #d3d3d3,
        #c5c5c5,
        #c0c0c0,
        #bfbfbf,
        #d0d0d0,
        #d9d9d9,
        #d1d1d1,
        #c5c5c5,
        #c8c8c8,
        #d7d7d7,
        #d5d5d5,
        #cdcdcd,
        #c4c4c4,
        #d9d9d9,
        #cecece,
        #c5c5c5,
        #c5c5c5,
        #cdcdcd,
        #d8d8d8,
        #d9d9d9,
        #d7d7d7
      )
      content-box,
    linear-gradient(#d4d4d4, #d4d4d4) padding-box,
    radial-gradient(rgba(120, 120, 120, 0.9), rgba(120, 120, 120, 0) 70%) 50%
      bottom/80% 0.46875em no-repeat border-box;
}

.custom-btn {
  @apply rounded-lg;
  width: 280px;
  height: 60px;
  color: #000;
  /*border-radius: 5px; */
  padding: 10px 25px;
  font-size: 16px;
  text-shadow: 0px -1px 0px rgba(0, 0, 0, 0.4);
  text-decoration: none;
  background: transparent;
  cursor: pointer;
  position: relative;
  display: inline-block;
  box-shadow: inset 0px 1px 0px rgba(255, 255, 255, 1),
    0px 1px 3px rgba(0, 0, 0, 0.3);
  outline: none;
  border: 1px solid #ba6;
}
.custom-btn:active {
  -webkit-transform: translateY(2px);
  transform: translateY(2px);
}

/* 7 */
.btn-7 {
  border-color: #7c7c7c;
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(rgba(0, 0, 0, 0.25)),
    to(#e6e6e6)
  );
}
.btn-7 a {
  @apply text-black;
}
.btn-7-text-blue a {
  @apply text-blue-800;
}

.btn-7 a:hover {
  @apply text-orange-500;
}
